import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthenticationContext } from '../../_context';
import { HttpClient, TApiError } from '../../_http';

function logout(): Promise<void> {
  return HttpClient.post('auth/logout');
}

export function useLogout() {
  const navigate = useNavigate();
  const { clearProfile } = useAuthenticationContext();

  return useMutation<void, TApiError>('logout', logout, {
    onSuccess: () => {
      clearProfile();
      navigate('auth/login');
    },
  });
}
