import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

function resendRegisterEmail(userId: string): Promise<void> {
  return HttpClient.post(`users/${userId}/resend-register-mail`);
}

export function useResendRegisterEmail() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, string>('resendRegisterEmail', resendRegisterEmail, {
    onSuccess: () => queryClient.invalidateQueries('getUserDetail'),
  });
}
