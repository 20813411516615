import { TProfile } from '../../profile/_models';
import { TUser, UserState } from './User';

export function canUserBeDeactiveated(user: TUser): boolean {
  return user.state === UserState.Active;
}

export function canResendRegisterEmailForUser(user: TUser): boolean {
  return [UserState.Inactive, UserState.Registering].includes(user.state);
}

export function canUserBeDeleted(user: TUser, profile: TProfile): boolean {
  return user.id !== profile.id;
}
