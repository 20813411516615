import { FC } from 'react';

import I18n from '../../_translations/I18n';
import { formatISOString } from '../../_utils/dateHelpers';

import './timestamps.scss';

type TProps = {
  entity: {
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
  };
};

function formatTimestamp(at: string, by: string, isCreation: boolean): string {
  const params: Record<string, string> = { date: formatISOString(at, 'dd/MM/yyyy HH:mm'), name: by };
  if (isCreation) return I18n.t(by ? 'SHARED.DETAIL.CREATED_AT_BY' : 'SHARED.DETAIL.CREATED_AT', params);
  return I18n.t(by ? 'SHARED.DETAIL.UPDATED_AT_BY' : 'SHARED.DETAIL.UPDATED_AT', params);
}

const Timestamps: FC<TProps> = ({ entity }) => {
  const { createdAt, createdBy, updatedAt, updatedBy } = entity;
  return (
    <div className="timestamps">
      {createdAt && <div>{formatTimestamp(createdAt, createdBy, true)}</div>}
      {updatedAt && <div>{formatTimestamp(updatedAt, updatedBy, false)}</div>}
    </div>
  );
};

export default Timestamps;
