import { FC, MouseEvent } from 'react';
import classnames from 'classnames';

import * as ICONS from '../../_assets/svg';

import './icon.scss';

type TProps = {
  className?: string;
  clickable?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  name: string;
  onClick?: (value: MouseEvent) => void;
  size?: number;
};

const Icon: FC<TProps> = ({ size = 2, name, onClick, disabled, className = '', label, clickable, id, ...otherProps }) => {
  const Svg = ICONS[name];
  if (!Svg) return null;

  const sizeObject = size ? { height: `${size}rem`, width: `${size}rem` } : {};
  const IconComponent = (
    <i {...otherProps} className={classnames('icon', className)} style={sizeObject}>
      <Svg {...sizeObject} />
    </i>
  );
  if (!clickable && !onClick) return IconComponent;
  return (
    <button
      aria-label={label}
      className={classnames('plain', className)}
      disabled={disabled}
      id={id}
      onClick={value => onClick?.(value)}
      style={sizeObject}
      type="button"
    >
      {IconComponent}
    </button>
  );
};

export default Icon;
