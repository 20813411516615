import { useInfiniteQuery } from 'react-query';

import { HttpClient, TApiError, THttpMetadataQuery, THttpPagedResponse } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { TRole } from '../_models';

function getRoles(query?: THttpMetadataQuery): Promise<THttpPagedResponse<TRole>> {
  return HttpClient.get<THttpPagedResponse<TRole>>(`roles${getQueryParams(query)}`);
}

export function useGetRoles(query?: THttpMetadataQuery) {
  return useInfiniteQuery<THttpPagedResponse<TRole>, TApiError>('getRoles', () => getRoles(query), {
    getNextPageParam: lastPage => lastPage.meta,
  });
}
