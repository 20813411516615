import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './checkboxGroup.scss';

export type TCheckboxGroupProps = TInputWrapperProps & {
  children: ReactNode;
  horizontal?: boolean;
};

const CheckboxGroup: FC<TCheckboxGroupProps> = ({ children, horizontal = false, ...wrapperProps }) => {
  const { validation } = wrapperProps;

  return (
    <InputWrapper {...wrapperProps} showError={!!validation?.message}>
      <div className={classnames('checkbox-group', { horizontal })}>{children}</div>
    </InputWrapper>
  );
};

export default CheckboxGroup;
