import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { HttpClient, TApiError } from '../../_http';
import I18n from '../../_translations/I18n';
import { removeEmptyKeys } from '../../_utils/objectHelpers';
import { TUpdateUserRequestBody } from '../_models';

function updateUser({ userId, body }: TUpdateUserRequestBody): Promise<void> {
  return HttpClient.put(`users/${userId}`, removeEmptyKeys(body));
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TUpdateUserRequestBody>('updateUser', updateUser, {
    onSuccess: () => {
      toast.success(I18n.t('USERS.TOASTER.USER_UPDATED'));
      queryClient.invalidateQueries('getUserDetail');
    },
  });
}
