import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useInfiniteScroll, useTableSort } from '../../_hooks';
import { TFillMetadataQueryFunction, THttpMetadataPagingResponse, THttpSortDirection } from '../../_http';
import Table, { TTableColumn } from '../../_shared/table/Table';
import I18n from '../../_translations/I18n';
import { formatISOString } from '../../_utils/dateHelpers';
import { TRole } from '../_models';

type Props = {
  data?: TRole[];
  isLoading: boolean;
  metadata: THttpMetadataPagingResponse;
  setQuery: TFillMetadataQueryFunction;
};

const columns: TTableColumn[] = [
  { label: I18n.t('ROLES.NAME'), name: 'name', sortable: true },
  { label: I18n.t('ROLES.OVERVIEW.CREATED_AT'), name: 'createdAt', sortable: true },
  { label: I18n.t('ROLES.OVERVIEW.UPDATED_AT'), name: 'updatedAt', sortable: true },
  { label: I18n.t('ROLES.PERMISSIONS.TITLE'), name: 'permissions' },
];

const RolesTable: FC<Props> = ({ data, isLoading, setQuery, metadata }) => {
  const { sortFunctions } = useTableSort((column: string, direction: THttpSortDirection) =>
    setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
  );
  const { t } = useTranslation();

  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  function renderRow(role: TRole): JSX.Element {
    return (
      <Table.Row key={role.id}>
        <Table.Cell>
          <Link to={role.id}>{role.name}</Link>
        </Table.Cell>
        <Table.Cell>{formatISOString(role.createdAt)}</Table.Cell>
        <Table.Cell>{formatISOString(role.updatedAt)}</Table.Cell>
        <Table.Cell>
          <ul className="permissions">
            {Object.keys(role.permissions).map(permission => {
              const options = Object.keys(role.permissions[permission]).filter(option => role.permissions[permission][option]);
              if (!options?.length) return '';
              return (
                <li key={permission}>
                  <span>{`${permission}: `}</span>
                  {options.join(', ')}
                </li>
              );
            })}
          </ul>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      emptyLabel={t('ROLES.OVERVIEW.EMPTY')}
      isLoading={isLoading}
      renderRow={renderRow}
      sortFunctions={sortFunctions}
    />
  );
};

export default RolesTable;
