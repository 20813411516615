import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../_shared';
import { TUserForm } from '../_models';
import { useCreateUser } from '../_queries';
import UserForm from '../edit/UserForm';

const initialForm: TUserForm = {
  email: '',
  firstName: '',
  lastName: '',
  roleIds: [],
};

const CreateUser: FC = () => {
  const { error, isLoading, mutate: createUser } = useCreateUser();
  const { t } = useTranslation();

  return (
    <main className="left-container">
      <header>
        <h1>{t('USERS.CREATE.TITLE')}</h1>
      </header>
      <section>
        <UserForm
          buttons={<Button href="/users">{t('SHARED.BUTTONS.CANCEL')}</Button>}
          error={error}
          initialForm={initialForm}
          isSubmitting={isLoading}
          submitForm={(values: TUserForm) => createUser(values)}
        />
      </section>
    </main>
  );
};

export default CreateUser;
