import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { HttpClient, TApiError } from '../../_http';
import I18n from '../../_translations/I18n';
import { ModalOpener } from '../../modal/ModalOpener';
import { TChangePasswordForm } from '../_models';

function changePassword(body: TChangePasswordForm): Promise<void> {
  const payload = {
    newPassword: body.newPassword,
    oldPassword: body.oldPassword,
  };
  return HttpClient.post('auth/change-password', payload);
}

export function useChangePassword() {
  return useMutation<void, TApiError, TChangePasswordForm>('changePassword', changePassword, {
    onSuccess: () => {
      toast.success(I18n.t('AUTH.TOASTER.CHANGE_PASSWORD'));
      ModalOpener.instance.close();
    },
  });
}
