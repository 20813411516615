import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { HttpClient, TApiError } from '../../_http';
import I18n from '../../_translations/I18n';
import { TChoosePasswordRequestBody } from '../_models';

function choosePassword(body: TChoosePasswordRequestBody): Promise<void> {
  return HttpClient.post('auth/reset-password', body);
}

export function useChoosePassword() {
  const navigate = useNavigate();

  return useMutation<void, TApiError, TChoosePasswordRequestBody>('choosePassword', choosePassword, {
    onSuccess: () => {
      toast.success(I18n.t('AUTH.TOASTER.CHOOSE_PASSWORD'));
      navigate('/auth/login');
    },
  });
}
