import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import classnames from 'classnames';

import { useAuthenticationContext } from '../../_context';
import { Button, GoBackLink, Spinner, Timestamps } from '../../_shared';
import I18n from '../../_translations/I18n';
import { TUserForm, UserState } from '../_models';
import { canResendRegisterEmailForUser, canUserBeDeactiveated, canUserBeDeleted } from '../_models/rules';
import { useDeactivateUser, useDeleteUser, useGetUserDetail, useResendRegisterEmail, useUpdateUser } from '../_queries';
import { labelForUserState } from '../_utils';
import UserForm from '../edit/UserForm';

import './userDetail.scss';

function descriptionForUserState(state: UserState): string {
  switch (state) {
    case UserState.Active:
      return I18n.t('USERS.DETAIL.STATUS.DESCRIPTION.ACTIVE');
    case UserState.Registering:
      return I18n.t('USERS.DETAIL.STATUS.DESCRIPTION.REGISTERING');
    case UserState.Inactive:
      return I18n.t('USERS.DETAIL.STATUS.DESCRIPTION.INACTIVE');
    default:
      return null;
  }
}

const UserDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { profile } = useAuthenticationContext();
  const { t } = useTranslation();
  const { data: user, isLoading: isGetUserDetailLoading, error: errorGetUserDetail } = useGetUserDetail(id);
  const { isLoading: isDeleteLoading, error: errorDelete, mutate: deleteUser } = useDeleteUser();
  const { isLoading: isUpdateLoading, error: errorUpdate, mutate: updateUser } = useUpdateUser();
  const { isLoading: isDeactivateLoading, error: errorDeactivate, mutate: deactivateUser } = useDeactivateUser();
  const {
    isLoading: isResendRegisterMailLoading,
    error: errorResendRegisterMail,
    mutate: resendRegisterMail,
  } = useResendRegisterEmail();
  const error = errorGetUserDetail || errorDelete || errorUpdate || errorDeactivate || errorResendRegisterMail;

  if (!user) {
    if (error) return <Navigate to="/users" />;
    if (isGetUserDetailLoading) return <Spinner overlay>{t('USERS.DETAIL.LOADING')}</Spinner>;
    return null;
  }

  function renderDetailsSection() {
    const initialForm: TUserForm = {
      email: user.email,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      roleIds: user.roles.map(role => role.id),
    };

    return (
      <section>
        <h2>{t('USERS.DETAIL.DETAILS.TITLE')}</h2>
        <UserForm
          buttons={
            canUserBeDeleted(user, profile) && (
              <Button loading={isDeleteLoading} onClick={() => deleteUser(user.id)} theme="negative">
                {t('USERS.DETAIL.BUTTON_DELETE')}
              </Button>
            )
          }
          error={error}
          initialForm={initialForm}
          isSubmitting={isUpdateLoading}
          submitForm={(body: TUserForm) => updateUser({ body, userId: user.id })}
          userId={user.id}
        />
      </section>
    );
  }

  function renderStatusSection() {
    let button = null;
    if (canUserBeDeactiveated(user)) {
      button = (
        <Button loading={isDeactivateLoading} onClick={() => deactivateUser(user.id)} theme="negative">
          {t('USERS.DETAIL.STATUS.BUTTON.DEACTIVATE')}
        </Button>
      );
    } else if (canResendRegisterEmailForUser(user)) {
      button = (
        <Button loading={isResendRegisterMailLoading} onClick={() => resendRegisterMail(user.id)} theme="primary">
          {t(
            user.state === UserState.Registering
              ? 'USERS.DETAIL.STATUS.BUTTON.RESEND_REGISTER_MAIL'
              : 'USERS.DETAIL.STATUS.BUTTON.ACTIVATE',
          )}
        </Button>
      );
    }
    return (
      <section className="section-status">
        <h2>{t('USERS.DETAIL.STATUS.TITLE')}</h2>
        <div>
          <span>{`${t('USERS.DETAIL.STATUS.SUBTITLE')} `}</span>
          <span className={classnames('status', user.state)}>{labelForUserState(user.state)}</span>
        </div>
        <div className="description">
          <span>{descriptionForUserState(user.state)}</span>
        </div>
        <div className="actions">{profile?.permissions?.roles.edit && button}</div>
      </section>
    );
  }

  return (
    <main className="left-container">
      <GoBackLink label={t('USERS.DETAIL.BACK')} to="/users" />
      <header>
        <h1>{user.email}</h1>
        <Timestamps entity={user} />
      </header>
      {renderDetailsSection()}
      {renderStatusSection()}
    </main>
  );
};

export default UserDetail;
