import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { HttpClient, TApiError } from '../../_http';
import { TRequestPasswordResetForm } from '../_models';

function requestPasswordReset(body: TRequestPasswordResetForm): Promise<void> {
  return HttpClient.post('auth/reset-password-request', body);
}

export function useRequestPasswordReset() {
  const navigate = useNavigate();

  return useMutation<void, TApiError, TRequestPasswordResetForm>('requestPasswordReset', requestPasswordReset, {
    onSuccess: () => navigate('auth/login'),
  });
}
