import { FC, useMemo } from 'react';

import { Dropdown } from '../../_shared';
import { TValidatorResponse } from '../../_utils/formValidation';
import { useGetRoles } from '../_queries';

type TProps = {
  label: string;
  name: string;
  onChange: (value: string[], name: string) => void;
  required?: boolean;
  validation?: TValidatorResponse;
  value: string[];
};

const RolesDropdown: FC<TProps> = ({ label, name, value, onChange, validation, required }) => {
  const { data: roles } = useGetRoles();

  const options = useMemo(
    () =>
      roles?.pages[0].data.map(role => ({
        label: role.name,
        value: role.id,
      })),
    [roles],
  );
  return (
    <Dropdown
      label={label}
      multiple
      name={name}
      onChange={onChange}
      options={options}
      required={required}
      validation={validation}
      value={value}
    />
  );
};

export default RolesDropdown;
