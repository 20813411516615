import I18n from '../../_translations/I18n';
import { UserState } from '../_models';

export function labelForUserState(state: UserState): string {
  switch (state) {
    case UserState.Active:
      return I18n.t('USERS.STATE.OPTIONS.ACTIVE');
    case UserState.Registering:
      return I18n.t('USERS.STATE.OPTIONS.REGISTERING');
    case UserState.Inactive:
      return I18n.t('USERS.STATE.OPTIONS.INACTIVE');
    default:
      return null;
  }
}
