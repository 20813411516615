import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { HttpClient, TApiError } from '../../_http';
import I18n from '../../_translations/I18n';
import { removeEmptyKeys } from '../../_utils/objectHelpers';
import { TUpdateRoleRequestBody } from '../_models';

function updateRole({ roleId, body }: TUpdateRoleRequestBody): Promise<void> {
  return HttpClient.put(`roles/${roleId}`, removeEmptyKeys(body));
}

export function useUpdateRole() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TUpdateRoleRequestBody>('updateRole', updateRole, {
    onSuccess: () => {
      toast.success(I18n.t('ROLES.TOASTER.ROLE_UPDATED'));
      queryClient.invalidateQueries('getRoleDetail');
    },
  });
}
