import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { Button, GoBackLink, Spinner, Timestamps } from '../../_shared';
import { TRoleForm } from '../_models';
import { useDeleteRole, useGetRoleDetail, useUpdateRole } from '../_queries';
import RoleForm from '../edit/RoleForm';

const RoleDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isLoading: isGetRoleDetailLoading, error: getRoleDetailError, data: role } = useGetRoleDetail(id);
  const { isLoading: isDeleteLoading, error: errorDelete, mutate: deleteRole } = useDeleteRole();
  const { isLoading: isUpdateLoading, error: errorUpdate, mutate: updateRole } = useUpdateRole();
  const error = getRoleDetailError || errorDelete || errorUpdate;

  if (!role) {
    if (error) return <Navigate to="/roles" />;
    if (isGetRoleDetailLoading) return <Spinner overlay>{t('USERS.DETAIL.LOADING')}</Spinner>;
    return null;
  }

  function renderDetailsSection() {
    const initialForm: TRoleForm = {
      name: role.name,
      permissions: role.permissions,
    };

    return (
      <section>
        <RoleForm
          buttons={
            <Button loading={isDeleteLoading} onClick={() => deleteRole(role.id)} theme="negative">
              {t('ROLES.DETAIL.BUTTON_DELETE')}
            </Button>
          }
          error={error}
          initialForm={initialForm}
          isSubmitting={isUpdateLoading}
          roleId={role.id}
          submitForm={(body: TRoleForm) => updateRole({ body, roleId: role.id })}
        />
      </section>
    );
  }

  return (
    <main className="left-container">
      <GoBackLink label={t('ROLES.DETAIL.BACK')} to="/roles" />
      <header>
        <h1>{role.name}</h1>
        <Timestamps entity={role} />
      </header>
      {renderDetailsSection()}
    </main>
  );
};

export default RoleDetail;
