import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import classnames from 'classnames';

import { useInputError } from '../../../_hooks';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './dropdown.scss';

export type TDropdownOption = {
  label: string;
  value: string;
};

type TProps = TInputWrapperProps & {
  multiple?: boolean;
  normalize?: (value: string) => string;
  onChange: (value: string | string[], name: string) => void;
  options: TDropdownOption[] | [];
  placeholder?: string;
  value: string | string[];
};

const Dropdown: FC<TProps> = ({
  multiple,
  normalize = (value: string) => value,
  onChange,
  options,
  placeholder,
  value,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const { t } = useTranslation();

  let allOptions: TDropdownOption[] = options || [];
  const containsEmptyOption = value && !multiple;
  if (containsEmptyOption) {
    allOptions = [{ label: t('SHARED.DROPDOWN.OPTION_CLEAR_VALUE'), value: null }, ...allOptions];
  }

  return (
    <InputWrapper {...wrapperProps} showError={showError}>
      <Select
        className={classnames('dropdown', { 'contains-empty-option': containsEmptyOption, error: showError })}
        classNamePrefix="dropdown"
        isClearable={false}
        isDisabled={disabled}
        isMulti={multiple}
        isSearchable
        name={name}
        noOptionsMessage={() => t('SHARED.DROPDOWN.NO_OPTIONS')}
        onChange={(option: TDropdownOption | TDropdownOption[]) => {
          let normalizedValue = null;
          if (!option && Array.isArray(value)) normalizedValue = [];
          else if (Array.isArray(option)) normalizedValue = option.map(opt => normalize(opt.value));
          else normalizedValue = normalize(option.value);
          onChange(normalizedValue, name);
          setDirty();
        }}
        options={allOptions}
        placeholder={placeholder}
        value={allOptions?.filter(option => (Array.isArray(value) ? value.includes(option.value) : option.value === value))}
      />
    </InputWrapper>
  );
};

export default Dropdown;
