import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '../../_hooks';
import { TFormValidationErrors, TSubmitFormFunction } from '../../_hooks/useForm';
import { TApiError } from '../../_http';
import { Button, Checkbox, CheckboxGroup, ErrorMessage, InputField } from '../../_shared';
import I18n from '../../_translations/I18n';
import { formValidator } from '../../_utils/formValidation';
import { TRoleForm } from '../_models';

import './roleForm.scss';

type TProps = {
  buttons?: JSX.Element | JSX.Element[];
  error?: TApiError;
  initialForm: TRoleForm;
  isSubmitting?: boolean;
  roleId?: string;
  submitForm: TSubmitFormFunction<TRoleForm>;
};

function validateForm(values: TRoleForm): TFormValidationErrors<TRoleForm> {
  return {
    name: formValidator.required(values.name),
  };
}

function errorAsString(error?: TApiError): string {
  if (error?.error === 'ROLE_IN_USE') return I18n.t('ROLES.ERRORS.ROLE_IN_USE');
  if (error?.error === 'ROLE_NAME_ALREADY_IN_USE') return I18n.t('ROLES.ERRORS.ROLE_NAME_ALREADY_IN_USE');
  if (error?.error === 'PERMISSION_DENIED') return I18n.t('ERRORS.PERMISSION_DENIED');
  return null;
}

function labelForPermissionFeature(feature: string): string {
  if (feature.toUpperCase() === 'ROLES') return I18n.t('ROLES.PERMISSIONS.FEATURES.ROLES');
  if (feature.toUpperCase() === 'USERS') return I18n.t('ROLES.PERMISSIONS.FEATURES.USERS');
  return null;
}

function labelForPermissionRight(right: string): string {
  if (right.toUpperCase() === 'EDIT') return I18n.t('ROLES.PERMISSIONS.RIGHTS.EDIT');
  if (right.toUpperCase() === 'VIEW') return I18n.t('ROLES.PERMISSIONS.RIGHTS.VIEW');
  return null;
}

const RoleForm: FC<TProps> = ({ roleId, initialForm, submitForm, isSubmitting, error, buttons }) => {
  const form = useForm<TRoleForm>({ error, initialForm, submitForm, validateForm });
  const errorMessage = errorAsString(error);
  const { t } = useTranslation();

  return (
    <form onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      <div role="group">
        <InputField
          label={t('ROLES.NAME')}
          name="name"
          onChange={form.setAttribute}
          required
          type="text"
          validation={form.validationErrors.name}
          value={form.values.name}
        />
        <div />
      </div>
      <div className="permissions">
        <h3>{t('ROLES.PERMISSIONS.TITLE')}</h3>
        {Object.keys(form.values.permissions).map(permission => (
          <CheckboxGroup horizontal key={permission} label={labelForPermissionFeature(permission)} name={permission}>
            {Object.keys(form.values.permissions[permission]).map(option => {
              const optionName = `${permission}.${option}`;
              return (
                <Checkbox
                  checked={form.values.permissions[permission][option]}
                  key={optionName}
                  label={labelForPermissionRight(option)}
                  name={optionName}
                  onChange={value => form.setValues(values => (values.permissions[permission][option] = value))}
                />
              );
            })}
          </CheckboxGroup>
        ))}
      </div>
      <div className="actions">
        {buttons}
        <Button loading={isSubmitting} theme="primary" type="submit">
          {t(roleId ? 'SHARED.BUTTONS.SAVE' : 'SHARED.BUTTONS.CREATE')}
        </Button>
      </div>
    </form>
  );
};

export default RoleForm;
