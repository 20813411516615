import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuthenticationContext } from '../_context';
import CreateUser from './create/CreateUser';
import UserDetail from './detail/UserDetail';
import UsersOverview from './overview/UsersOverview';

const UsersRoutes: FC = () => {
  const { profile } = useAuthenticationContext();

  return (
    <Routes>
      <Route element={<UsersOverview />} path="*" />
      {profile?.permissions?.users.edit && <Route element={<CreateUser />} path="create" />}
      <Route element={<UserDetail />} path=":id" />
    </Routes>
  );
};

export default UsersRoutes;
