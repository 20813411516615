import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { useInfiniteScroll, useTableSort } from '../../_hooks';
import { TFillMetadataQueryFunction, THttpMetadataPagingResponse, THttpSortDirection } from '../../_http';
import Table, { TTableColumn } from '../../_shared/table/Table';
import I18n from '../../_translations/I18n';
import { formatISOString } from '../../_utils/dateHelpers';
import { TUser, UserState } from '../_models';
import { labelForUserState } from '../_utils';

type Props = {
  data?: TUser[];
  isLoading: boolean;
  metadata: THttpMetadataPagingResponse;
  setQuery: TFillMetadataQueryFunction;
};

const columns: TTableColumn[] = [
  { label: I18n.t('USERS.EMAIL'), name: 'email', sortable: true },
  { label: I18n.t('USERS.FIRST_NAME'), name: 'firstName', sortable: true },
  { label: I18n.t('USERS.LAST_NAME'), name: 'lastName', sortable: true },
  { label: I18n.t('USERS.OVERVIEW.CREATED_AT'), name: 'createdAt', sortable: true },
  { label: I18n.t('USERS.OVERVIEW.UPDATED_AT'), name: 'updatedAt', sortable: true },
  { label: I18n.t('USERS.STATE.TITLE'), name: 'state', sortable: true },
];

const UsersTable: FC<Props> = ({ data, isLoading, setQuery, metadata }) => {
  const { sortFunctions } = useTableSort((column: string, direction: THttpSortDirection) =>
    setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
  );
  const { t } = useTranslation();

  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  function renderRow(user: TUser): JSX.Element {
    const className = classnames({ 'greyed-out': user.state === UserState.Inactive });
    return (
      <Table.Row className={className} key={user.id}>
        <Table.Cell>
          <Link to={user.id}>{user.email}</Link>
        </Table.Cell>
        <Table.Cell>{user.firstName}</Table.Cell>
        <Table.Cell>{user.lastName}</Table.Cell>
        <Table.Cell>{formatISOString(user.createdAt)}</Table.Cell>
        <Table.Cell>{formatISOString(user.updatedAt)}</Table.Cell>
        <Table.Cell>{labelForUserState(user.state)}</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      emptyLabel={t('USERS.OVERVIEW.EMPTY')}
      isLoading={isLoading}
      renderRow={renderRow}
      sortFunctions={sortFunctions}
    />
  );
};

export default UsersTable;
