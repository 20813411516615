import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { SvgLogo } from '../../../_assets/svg';
import { useAuthenticationContext } from '../../../_context';
import { Icon } from '../../../_shared';
import { useLogout } from '../../../auth/_queries';
import { hasRolesPermissions, hasUsersPermissions } from '../../../profile/_utils';

import './menu.scss';

const Menu: FC = () => {
  const { profile } = useAuthenticationContext();
  const { mutate: logout } = useLogout();
  const { t } = useTranslation();

  return (
    <header className="main-menu">
      <NavLink className="logo" to="/">
        <SvgLogo />
      </NavLink>
      <nav>
        <div>
          {hasUsersPermissions(profile?.permissions) && <NavLink to="/users">{t('SHARED.NAVIGATION.USERS')}</NavLink>}
          {hasRolesPermissions(profile?.permissions) && <NavLink to="/roles">{t('SHARED.NAVIGATION.ROLES')}</NavLink>}
        </div>
        <NavLink to="/profile">
          <Icon name="SvgUser" size={2} />
          <span>{profile.firstName || profile.email}</span>
        </NavLink>
      </nav>
      <Icon label={t('AUTH.LOGOUT')} name="SvgLogout" onClick={() => logout()} size={1.6} />
    </header>
  );
};

export default Menu;
