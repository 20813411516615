import { FC, ReactElement, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, INLINES } from '@contentful/rich-text-types';
import classNames from 'classnames';

import './markdown.scss';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a href={node.data.uri} rel="noopener noreferrer" target="_blank">
          {node.content[0].value}
        </a>
      );
    },
  },
};

type TProps = {
  className?: string;
  value: Document;
};

const Markdown: FC<TProps> = ({ value, className }) => {
  const id = `markdown-${Math.random() * 1000}`;

  useLayoutEffect(() => {
    const rootElement = document.getElementById(id);
    ReactDOM.render(documentToReactComponents(value, options) as ReactElement, rootElement);
  }, [value]);

  return <div className={classNames('markdown', { className })} id={id} />;
};
export default Markdown;
