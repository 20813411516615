import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '../../_hooks';
import { TFormValidationErrors } from '../../_hooks/useForm';
import { TApiError } from '../../_http';
import { Button, ErrorMessage, InputField } from '../../_shared';
import I18n from '../../_translations/I18n';
import { formValidator } from '../../_utils/formValidation';
import Modal from '../../modal/Modal';
import { ModalOpener } from '../../modal/ModalOpener';
import { TChangePasswordForm } from '../_models';
import { useChangePassword } from '../_queries';

const initialForm: TChangePasswordForm = {
  newPassword: '',
  oldPassword: '',
  repeatNewPassword: '',
};

function validateForm(values: TChangePasswordForm): TFormValidationErrors<TChangePasswordForm> {
  // No validation on the oldPassword because this is normally aligned with the validation rules
  const validation: TFormValidationErrors<TChangePasswordForm> = {
    newPassword: formValidator.password(values.newPassword),
  };
  if (validation.newPassword.isValid) {
    validation.repeatNewPassword = formValidator.matchingPasswords(values.newPassword, values.repeatNewPassword);
  }
  return validation;
}

function errorAsString(error?: TApiError): string {
  if (error?.error === 'INVALID_OLD_PASSWORD') return I18n.t('AUTH.ERRORS.INVALID_OLD_PASSWORD');
  return null;
}

const ChangePasswordModal: FC = () => {
  const { isLoading, error, mutate: changePassword } = useChangePassword();
  const errorMessage = errorAsString(error);
  const form = useForm<TChangePasswordForm>({
    error,
    initialForm,
    submitForm: values => changePassword(values),
    validateForm,
  });
  const { t } = useTranslation();

  return (
    <Modal>
      <form onSubmit={form.submit}>
        <Modal.Header>{t('AUTH.CHANGE_PASSWORD.TITLE')}</Modal.Header>
        <Modal.Content>
          <p className="description">{t('AUTH.PASSWORD_GUIDELINES')}</p>
          <ErrorMessage isGlobal isVisible={!!errorMessage}>
            {errorMessage}
          </ErrorMessage>
          <InputField
            label={t('AUTH.CHANGE_PASSWORD.OLD_PASSWORD')}
            name="oldPassword"
            onChange={form.setAttribute}
            type="password"
            validation={form.validationErrors.oldPassword}
            value={form.values.oldPassword}
          />
          <InputField
            label={t('AUTH.CHANGE_PASSWORD.NEW_PASSWORD')}
            name="newPassword"
            onChange={form.setAttribute}
            type="password"
            validation={form.validationErrors.newPassword}
            value={form.values.newPassword}
          />
          <InputField
            label={t('AUTH.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD')}
            name="repeatNewPassword"
            onChange={form.setAttribute}
            type="password"
            validation={form.validationErrors.repeatNewPassword}
            value={form.values.repeatNewPassword}
          />
        </Modal.Content>
        <Modal.Buttons>
          <Button onClick={() => ModalOpener.instance.close()}>{t('SHARED.BUTTONS.CANCEL')}</Button>
          <Button loading={isLoading} theme="primary" type="submit">
            {t('SHARED.BUTTONS.SAVE')}
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
