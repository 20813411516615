import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ChoosePassword from './choose-password/ChoosePassword';
import Login from './login/Login';
import RequestPasswordReset from './request-password-reset/RequestPasswordReset';

const AuthRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<Login />} path="login" />
      <Route element={<ChoosePassword />} path="register/:token" />
      <Route element={<ChoosePassword isPasswordReset />} path="choose-password/:token" />
      <Route element={<RequestPasswordReset />} path="request-password-reset" />
      <Route element={<Navigate to="login" />} path="*" />
    </Routes>
  );
};

export default AuthRoutes;
