import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Menu from './menu/Menu';

import './authorizedLayout.scss';

const AuthorizedLayout: FC = () => {
  return (
    <div className="authorized-layout">
      <Menu />
      <Outlet />
    </div>
  );
};

export default AuthorizedLayout;
