import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

import { SvgLogo } from '../../_assets/svg';

import './unauthorizedLayout.scss';

const UnauthorizedLayout: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="unauthorized-layout">
      <aside>
        <NavLink to="/">
          <SvgLogo />
          <span>{t('AUTH.SIDEBAR_TITLE')}</span>
        </NavLink>
      </aside>
      <Outlet />
    </div>
  );
};

export default UnauthorizedLayout;
