import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useForm } from '../../_hooks';
import { TFormValidationErrors } from '../../_hooks/useForm';
import { HttpStatus, TApiError } from '../../_http';
import { Button, ErrorMessage, InputField } from '../../_shared';
import I18n from '../../_translations/I18n';
import { formValidator } from '../../_utils/formValidation';
import { TLoginForm } from '../_models';
import { useLogin } from '../_queries';

import './login.scss';

const initialForm: TLoginForm = {
  password: '',
  username: '',
};

function validateForm(values: TLoginForm): TFormValidationErrors<TLoginForm> {
  return {
    password: formValidator.required(values.password),
    username: formValidator.email(values.username),
  };
}

function errorAsString(error?: TApiError): string {
  if (error?.statusCode === HttpStatus.Unauthorized) return I18n.t('AUTH.ERRORS.UNAUTHORIZED');
  if (error?.error === 'USER_STATE_NOT_ALLOWED') return I18n.t('AUTH.ERRORS.USER_STATE_NOT_ALLOWED');
  return null;
}

const Login: FC = () => {
  const { isLoading, error, mutate: login } = useLogin();
  const form = useForm<TLoginForm>({
    error,
    initialForm,
    submitForm: values => login(values),
    validateForm,
  });
  const errorMessage = errorAsString(error);
  const { t } = useTranslation();

  return (
    <main className="right-container login">
      <h1>{t('AUTH.LOGIN.TITLE')}</h1>
      <form onSubmit={form.submit}>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <InputField
          autoComplete="username"
          label={t('AUTH.LOGIN.USERNAME')}
          name="username"
          onChange={form.setAttribute}
          type="email"
          validation={form.validationErrors.username}
          value={form.values.username}
        />
        <InputField
          autoComplete="current-password"
          label={t('AUTH.LOGIN.PASSWORD')}
          name="password"
          onChange={form.setAttribute}
          type="password"
          validation={form.validationErrors.password}
          value={form.values.password}
        />
        <div className="actions">
          <Link to="/auth/request-password-reset">{t('AUTH.LOGIN.FORGOT_PASSWORD')}</Link>
          <Button loading={isLoading} theme="primary" type="submit">
            {t('AUTH.LOGIN.LOGIN')}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default Login;
