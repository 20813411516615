import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthenticationContext } from '../../_context';
import { HttpClient, TApiError } from '../../_http';
import { TProfile } from '../../profile/_models';
import { TLoginForm } from '../_models';

function login(body: TLoginForm): Promise<TProfile> {
  return HttpClient.post<TProfile>('auth/login', body);
}

export function useLogin() {
  const navigate = useNavigate();
  const { setProfile } = useAuthenticationContext();

  return useMutation<TProfile, TApiError, TLoginForm>('login', login, {
    onSuccess: (data: TProfile) => {
      setProfile(data);
      navigate('/');
    },
  });
}
