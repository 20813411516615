import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../_shared';
import { labelForLocale, languages } from '../_translations';
import ChangePasswordModal from '../auth/change-password/ChangePasswordModal';
import { Config } from '../config';
import { ModalOpener } from '../modal/ModalOpener';

const Profile: FC = () => {
  const { t, i18n } = useTranslation();
  const otherLanguage = i18n.language === languages.English ? languages.Dutch : languages.English;
  const isDevMode = i18n.language === 'cimode';

  return (
    <main className="left-container">
      <h1>{t('PROFILE.TITLE', { brandName: Config.brandName })}</h1>
      <section className="section-locale">
        <h2>{t('PROFILE.LOCALE.TITLE')}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: t('PROFILE.LOCALE.DESCRIPTION', {
              locale: t(labelForLocale(i18n.language)),
            }),
          }}
        />
        <div className="actions">
          <Button disabled={isDevMode} onClick={() => i18n.changeLanguage(otherLanguage)}>
            {t('PROFILE.LOCALE.BUTTON', { locale: t(labelForLocale(otherLanguage)) })}
          </Button>
        </div>
      </section>
      <section className="section-dev-mode">
        <h2>{t('PROFILE.DEV_MODE.TITLE')}</h2>
        <div className="description">
          <span>{t('PROFILE.DEV_MODE.DESCRIPTION')}</span>
        </div>
        <div className="actions">
          <Button onClick={() => i18n.changeLanguage(isDevMode ? otherLanguage : 'cimode')}>
            {t(isDevMode ? 'PROFILE.DEV_MODE.BUTTON.TURN_OFF' : 'PROFILE.DEV_MODE.BUTTON.TURN_ON')}
          </Button>
        </div>
      </section>
      <section className="section-change-password">
        <h2>{t('PROFILE.CHANGE_PASSWORD.TITLE')}</h2>
        <div className="description">{t('PROFILE.CHANGE_PASSWORD.DESCRIPTION')}</div>
        <div className="actions">
          <Button
            className="show-modal"
            onClick={() =>
              ModalOpener.instance.open({
                render: () => <ChangePasswordModal />,
              })
            }
          >
            {t('PROFILE.CHANGE_PASSWORD.TITLE')}
          </Button>
        </div>
      </section>
    </main>
  );
};

export default Profile;
