import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

function deactivateUser(userId: string): Promise<void> {
  return HttpClient.post(`users/${userId}/deactivate`);
}

export function useDeactivateUser() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, string>('deactivateUser', deactivateUser, {
    onSuccess: () => queryClient.invalidateQueries('getUserDetail'),
  });
}
