import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../_shared';
import { useAuthenticate } from '../auth/_queries';
import { TProfile } from '../profile/_models';

type TAuthenticationContext = {
  clearProfile: () => void;
  isLoggedIn: boolean;
  profile?: TProfile;
  setProfile: (profile: TProfile) => void;
};

const AuthenticationContext = createContext<TAuthenticationContext>({
  clearProfile: () => {},
  isLoggedIn: false,
  setProfile: () => {},
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

type Props = {
  children: ReactNode;
};

export const AuthenticationContextProvider: FC<Props> = ({ children }) => {
  const [profile, setProfile] = useState<TProfile>(null);
  const { isLoading } = useAuthenticate({
    onError: () => setProfile(null),
    onSuccess: (data: TProfile) => setProfile(data),
  });
  const { t } = useTranslation();

  return (
    <AuthenticationContext.Provider
      value={{
        clearProfile: () => setProfile(null),
        isLoggedIn: !!profile,
        profile,
        setProfile: (value: TProfile) => setProfile(value),
      }}
    >
      {isLoading ? <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner> : children}
    </AuthenticationContext.Provider>
  );
};
